import React from 'react';
import Layout from '../../layout/index.js'
import { withRouter, Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { authenticationService } from 'auth/authenticationService'
import {
  Grid, CircularProgress, Tooltip,
  Button, Box, Paper, Typography, TextField,
  IconButton, Alert, Card, CardContent,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@mui/material';

import AddCardIcon from '@mui/icons-material/AddCard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import EmailIcon from '@mui/icons-material/Email';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import MouseIcon from '@mui/icons-material/Mouse';


const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={'.'}
      decimalSeparator={','}
      isNumericString
      suffix=" CLP"
    />
  );
});


class TopUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      subscriptions: [],
      discount_ranges: [],
      webpay: {
        token: '',
        url: '',
      },
      manual: {
        token: '',
      },
      oneclick: {
        token: '',
      },
      oneclick_subscription: {
        token: '',
        url: '',
      },
      status: {
        amount: true,
      },
      html: {
        webpay_display: 'display-none',
        oneclick_display: 'display-none',
        manual_display: 'display-none',
        success_display: 'display-none',
        fail_display: 'display-none',
        loading: 'display-none',
      },
    };

    this.loadTopUp = this.loadTopUp.bind(this);
    this.loadSubscriptions = this.loadSubscriptions.bind(this);
    this.loadDiscountRanges = this.loadDiscountRanges.bind(this);
    this.createSubscription = this.createSubscription.bind(this);
    this.selectMethod = this.selectMethod.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleAddTopUp = this.handleAddTopUp.bind(this);
    this.handleAmountValidation = this.handleAmountValidation.bind(this);
    this.updateManualPayment = this.updateManualPayment.bind(this);
    this.setDefaultSubscription = this.setDefaultSubscription.bind(this);
  }

  handleInputChange(event) {
    const { data } = { ...this.state };
    const currentState = data;
    const { name, value } = event.target;
    currentState[name] = value;

    this.setState({ data: currentState });
  }

  loadTopUp() {
    authenticationService.fetchApi({
      url: '/topup/',
      method: 'POST',
    })
      .then((result) => {
        this.setState({ data: result });
      });

    this.setState((prevState) => ({
      html: {
        ...prevState.html,
        webpay_display: 'display-none',
        oneclick_display: 'display-none',
        manual_display: 'display-none',
        manual_newstate_display: 'display-none',
        select_display: 'display-inline',
        loading: 'display-none',
      },
    }));
  }

  loadSubscriptions() {
    authenticationService.fetchApi({
      url: '/subscription/',
      method: 'GET',
    })
      .then((result) => {
        this.setState({ subscriptions: result });
      });
  }

  loadDiscountRanges() {
    authenticationService.fetchApi({
      url: '/topup/discount_range/',
      method: 'GET',
    })
      .then((result) => {
        this.setState({ discount_ranges: result });
      });
  }

  componentDidMount() {
    this.loadTopUp(this.props.match.params.url_params);
    this.loadSubscriptions();
    this.loadDiscountRanges();

    this.setState({
      username: authenticationService.currentUser.username,
      company_object: authenticationService.currentUser.person_object.company_object,
    }, () => {
      if (this.state.company_object) {
        authenticationService.fetchApi({
          url: '/money_transaction/?grouped=true&page=1&rows_per_page=1&company_uuid=' + this.state.company_object.company_uuid,
          method: 'GET',
        })
          .then((result) => {
            this.setState({ balance: (result.totals['debit'] - result.totals['credit']) });
          })
          .catch((e) => { })
      }
    });
  }

  selectMethod(event, method) {
    event.preventDefault();
    this.setState((prevState) => ({
      html: {
        ...prevState.html,
        select_display: 'display-none',
        loading: 'display-inline',
      },
    }));

    const data = {
      top_up_uuid: this.state.data.top_up_uuid,
      amount: this.state.data.amount,
      method: method,
    }

    authenticationService.fetchApi({
      url: '/topup/payment/',
      method: 'POST',
      body: JSON.stringify(data),
    }).then((result) => {
      let methodClass = ''

      if (method === 'MANUAL') {
        methodClass = 'manual_display'
      } else if (method === 'WEBPAY') {
        methodClass = 'webpay_display'
      } else if (method === 'ONECLICK') {
        methodClass = 'oneclick_display';
      }

      this.setState((prevState) => ({
        html: {
          ...prevState.html,
          loading: 'display-none',
          [methodClass]: 'display-inline',
        },
        [method.toLowerCase()]: result,
      }));




    });
  };

  createSubscription(event, method) {
    event.preventDefault();

    const data = {
      method: method,
    }

    authenticationService.fetchApi({
      url: '/subscription/',
      method: 'POST',
      body: JSON.stringify(data),
    }).then((result) => {
      this.setState({ oneclick_subscription: result })
    });
  }

  setDefaultSubscription(subscription) {
    authenticationService.fetchApi({
      url: '/subscription/set_default/',
      method: 'POST',
      body: JSON.stringify({ subscription_uuid: subscription.subscription_uuid }),
    }).then((result) => {
      this.loadSubscriptions();
    });
  }

  updateManualPayment(event, method) {
    event.preventDefault();
    authenticationService.fetchApi({
      url: '/topup/manual_payment/',
      method: 'POST',
      body: JSON.stringify({
        token: this.state.manual.token,
        state: 'VALIDATION',
      }),
    }).then((result) => {
      this.setState((prevState) => ({
        html: {
          ...prevState.html,
          manual_display: 'display-none',
          manual_newstate_display: 'display-inline',
        },
      }));
    });
  }


  // deleteProduct(index, id_product_in_basket) {
  //   // console.log(algo)
  //   // console.log(index);
  //   // console.log(id_product_in_basket)
  //   let products = [...this.state.products];
  //   products.splice(index, 1);
  //   this.setState({ products });

  //   var params = {
  //     delete: id_product_in_basket,
  //   }

  //   authenticationService.fetchApi({
  //     url: '/basket/',
  //     method: 'POST',
  //     body: JSON.stringify(params),
  //   })
  //     .then(result => {
  //       console.log(result)
  //       this.setState({ data: result });
  //       this.setState({ products: result.products });
  //     });
  // }

  handleAddTopUp() {
    const amount_status = this.handleAmountValidation(this.state.amount);
    if (amount_status) {
      const params = {}
      params.amount = this.state.amount;
      authenticationService.fetchApi({
        url: '/topup/',
        method: 'POST',
        body: JSON.stringify(params),
      })
        .then((result) => {
          this.setState({ amount: '' });
          this.setState({ data: result });
          // this.loadTopUp();
        });
    }
  }

  handleAmountValidation(value) {
    let status = false;
    if (value > 9999) {
      status = true;
    }
    this.setState((prevState) => ({
      status: {
        ...prevState.status,
        amount: status,
      },
    }));
    return status;
  }



  render() {
    return (
      <Layout title='Carga de crédito'>
        <Paper>
          <Box p={1} m={1}>
            <Grid container>
              <Grid item md={3} sm={12}>
                <Box p={1} pr={2} pl={2}>
                  <Typography color="textSecondary">
                    Saldo actual
                  </Typography>
                  <Typography variant="h6" component="h6">
                    <NumberFormat displayType={'text'}
                      value={this.state.balance}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      prefix={''}
                      suffix={' CLP'}
                    />
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={5} sm={12}>
                <Box p={1} pl={2} >
                  <Typography variant='h6'>1) Iniciar carga de crédito</Typography>
                  <TextField name='amount'
                    id='amount'
                    helperText='El monto mínimo para cargar es 10.000 CLP'
                    label='Ingresa el monto a cargar'
                    type='text'
                    value={this.state.amount}
                    error={!this.state.status.amount}
                    onChange={(event) => this.setState({ amount: event.target.value })}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }} />
                </Box>
                <Box p={1} pl={2}>
                  <Button startIcon={<ShoppingCartIcon />} onClick={this.handleAddTopUp} color='primary' variant='contained' >Proceder al pago</Button>
                </Box>
              </Grid>
              <Grid item md={4} sm={12}>
                <Box p={1} pl={2} >
                  {this.state.discount_ranges &&
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 80 }} size="small" aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="right"><b>Monto mínimo de carga</b></TableCell>
                            <TableCell align="right"><b>Descuento</b></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.discount_ranges.map((row) => (
                            <TableRow
                              key={row.min_amount}
                            >
                              <TableCell align="right">
                                <NumberFormat displayType={'text'}
                                  value={row.min_amount}
                                  thousandSeparator={'.'}
                                  decimalSeparator={','}
                                  prefix={''}
                                  suffix={' CLP'}
                                />
                              </TableCell>
                              <TableCell align="right">{row.discount_percentage}%</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  }
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        {this.state.data && this.state.data.amount &&
          <Paper>
            <Box p={1} m={1}>
              <Grid container>
                <Grid item md={3} sm={12}>
                  <Box p={1} pr={2} pl={2}>
                    <Typography color="textSecondary">
                      Saldo a cargar
                    </Typography>
                    <Typography variant="h6" component="h6">
                      <NumberFormat displayType={'text'}
                        value={this.state.data.amount}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        prefix={''}
                        suffix={' CLP'}
                      />
                    </Typography>
                    <Typography color="textSecondary">
                      Monto total a pagar (Con IVA)
                    </Typography>
                    <Typography variant="h6" component="h6">
                      <NumberFormat displayType={'text'}
                        value={this.state.data.amount * 1.19}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        prefix={''}
                        suffix={' CLP'}
                      />
                    </Typography>
                    {/* <Typography color='textSecondary' variant='caption'>
                    Incluyendo IVA
                    </Typography> */}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className={this.state.html.select_display}>

                    <Box pb={1} px={1} mx={1}>
                      <Typography variant='h6'>2) Seleccione su medio de pago</Typography>

                      <Button startIcon={<AccountBalanceIcon />} color='primary' variant='contained' onClick={(event) => this.selectMethod(event, 'MANUAL')}>Transferencia bancaria</Button>
                    </Box>
                    <Box p={1} mx={1} >
                      <Button startIcon={<CreditCardIcon />} color='primary' variant='contained' onClick={(event) => this.selectMethod(event, 'WEBPAY')}>WEBPAY</Button>
                    </Box>
                    {this.state.subscriptions.length > 0 &&
                      <Box p={1} mx={1} align='center'>
                        <Button startIcon={<MouseIcon />} color='primary' variant='contained' onClick={(event) => this.selectMethod(event, 'ONECLICK')}>ONECLICK</Button>
                      </Box>
                    }
                  </div>

                  <div className={this.state.html.webpay_display}>
                    <Box p={1} m={1}>
                      <Typography variant='h6'>Iniciar Pago con WEBPAY</Typography>
                      {this.state.webpay.token !== '' ?
                        <form method="POST" action={this.state.webpay.url} className="form-edit">
                          <div className='form-group'>
                            <p>Al presionar en Iniciar Pago, serás re-direccionado a la página de WEBPAY donde podrás continuar con el proceso.</p>
                          </div>
                          <input type="hidden"
                            name="token_ws"
                            onChange={this.handleInputChange}
                            value={this.state.webpay.token} />
                          <Button type="submit" color='primary' variant='contained'>Iniciar pago con Webpay</Button>
                        </form> :
                        <Typography variant='body1'>Ocurrió un error. Favor intentar nuevamente.</Typography>
                      }
                    </Box>
                  </div>

                  <div className={this.state.html.oneclick_display}>
                    <Paper>
                      <Box p={1} m={1}>
                        <Typography variant='h6'>Pago con ONECLICK</Typography>
                        {this.state.oneclick.token !== '' ?
                          <Typography variant='body1'>Pago exitoso!</Typography> :
                          <Typography variant='body1'>Ocurrió un error. Favor intentar nuevamente.</Typography>
                        }
                      </Box>
                    </Paper>
                  </div>

                  <div className={this.state.html.manual_display}>
                    <Box p={1} m={1}>
                      <Typography variant='h6'>Pago a través de transferencia bancaria</Typography>

                      <p>
                        Te entregamos a continuación los datos de la cuenta bancaria para realizar tu transferencia:
                      </p>

                      <Box p={1} m={1}>
                        < Typography variant="body2" color="textSecondary" component="p">
                          Monto:{'\u00a0'}
                          <b><NumberFormat displayType={'text'}
                            value={Math.floor(this.state.data.amount * 1.19)}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            prefix={''}
                            suffix={' CLP'}
                          /></b>
                          <IconButton onClick={() => {
                            navigator.clipboard.writeText(Math.floor(this.state.data.amount * 1.19))
                          }} aria-label="copiar" size="small">
                            <FileCopyIcon fontSize="small" />
                          </IconButton>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          Cuenta Corriente Banco Santander: 82658173
                          <IconButton onClick={() => {
                            navigator.clipboard.writeText('82658173')
                          }} aria-label="copiar" size="small">
                            <FileCopyIcon fontSize="small" />
                          </IconButton>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          Nombre: AXONRT SPA
                          <IconButton onClick={() => {
                            navigator.clipboard.writeText('AXONRT SPA')
                          }} aria-label="copiar" size="small">
                            <FileCopyIcon fontSize="small" />
                          </IconButton>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          RUT: 77.131.642-5
                          <IconButton onClick={() => {
                            navigator.clipboard.writeText('771316425')
                          }} aria-label="copiar" size="small">
                            <FileCopyIcon fontSize="small" />
                          </IconButton>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          <b>Enviar comprobante a</b>: <Link button key="Email" component="a" href="mailto:contacto@axonio.dev">contacto@axonio.dev</Link>
                          <IconButton onClick={() => {
                            navigator.clipboard.writeText('contacto@axonio.div')
                          }} aria-label="copiar" size="small">
                            <FileCopyIcon fontSize="small" />
                          </IconButton>
                        </Typography>
                      </Box>
                      <div>
                        <p>
                          Una vez que hayas realizado el depósito, notificanos con el siguiente botón para que nosotros verifiquemos la transferencia y aprobemos tu pago.
                        </p>
                        <p>
                          Si tienes alguna consulta respecto a tu carga, contáctanos:
                        </p>
                        <Box>
                          <Tooltip title="Enviar correo" placement="top">
                            <IconButton href="mailto:contacto@axonio.dev" target="_top" aria-label="enviar">
                              <EmailIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Enviar Whatsapp" placement="top">
                            <IconButton href="https://wa.me/56982295321" target="_top" aria-label="whatsappear">
                              <WhatsAppIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Llamar a soporte" placement="top">
                            <IconButton href="tel:+56982295321" target="_top" aria-label="llamar">
                              <PhoneIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </div>
                      <Box pt={1}>
                        <Button onClick={this.updateManualPayment} color='primary' variant='contained'>Ya realicé el pago</Button>
                      </Box>
                    </Box>

                  </div>

                  <div className={this.state.html.manual_newstate_display}>
                    <Box p={1} m={1}>
                      <Typography variant='h6'>Validando transacción bancaria</Typography>
                      <div>
                        Nuestro equipo ha sido notificado de tu confirmación. Te notificaremos cuando la transferencia se haya aprobado.
                      </div>
                    </Box>
                  </div>

                  <div className={this.state.html.loading}>
                    <CircularProgress color="secondary" />
                  </div>

                </Grid>
              </Grid>
            </Box>
          </Paper>
        }
        {this.state.data &&
          <Paper>
            <Box py={2} px={4} my={3}>
              <Box pb={1}>
                <Typography variant='h6'>Recarga automática de saldo</Typography>
              </Box>
              <Box pb={1}>
                <Button startIcon={<AddCardIcon />} onClick={(event) => this.createSubscription(event, 'ONECLICK')} color='primary' variant='contained'>Agregar tarjeta</Button>
              </Box>
              <Box pb={2}>
                {this.state.oneclick_subscription.token !== '' &&
                  <form method="POST" action={this.state.oneclick_subscription.url} className="form-edit">
                    <div className='form-group'>
                      <p>Debes registrar una suscripción con WEBPAY ONECLICK para agregar el medio de pago.</p>
                    </div>
                    <input type="hidden"
                      name="TBK_TOKEN"
                      value={this.state.oneclick_subscription.token} />
                    <Button startIcon={<ExitToAppIcon />} type="submit" color='primary' variant='contained'>Iniciar Suscripción</Button>
                  </form>
                }
              </Box>
              {this.state.subscriptions.length === 0 &&
                <Alert severity="info">
                  Aún no tienes medios de pago agregados.
                </Alert>
              }
              {this.state.subscriptions.map((subscription, index) => {
                return (
                  <Box key={index} p={1}>
                    <Grid container spacing={2}>
                      <Grid item md={3} key={subscription.subscription_uuid}>
                        <Card variant="outlined">
                          <CardContent>

                            <Box>
                              <Typography variant="h6" component="h6">
                                💳 {subscription.one_click.card_type}
                                {!subscription.is_default &&
                                  <Tooltip title="Fijar como tarjeta por defecto para recargas automáticas">
                                    <IconButton
                                      onClick={() => {
                                        this.setDefaultSubscription(subscription)
                                      }}
                                      aria-label="por_defecto"
                                    >
                                      <FavoriteBorderIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                }
                                {subscription.is_default &&
                                  <Tooltip title="Tarjeta por defecto para recargas automáticas">
                                    <span>
                                      <IconButton disabled>
                                        <FavoriteIcon fontSize="small" color="primary" />
                                      </IconButton>
                                    </span>
                                  </Tooltip>
                                }
                              </Typography>
                              <Box>
                                <Typography variant="subtitle1">
                                  Tarjeta terminada en <b>**** {subscription.one_click.card_number.substring(subscription.one_click.card_number.length - 4)}</b>
                                </Typography>
                              </Box>
                            </Box>

                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Box>
                )
              })
              }
            </Box>
          </Paper>
        }
      </Layout>
    );
  }
}

export default withRouter(TopUp);
