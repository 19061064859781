import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import zxcvbn from 'zxcvbn';
import LoginLayout from 'layout/LoginLayout'
import {authenticationService} from 'auth/authenticationService'
import TagManager from 'react-gtm-module';
import {
  Typography, TextField, Button, Box, Divider, Alert,
  FormControlLabel, Switch, Checkbox,
  Dialog, DialogActions, DialogContent,
} from '@mui/material';

import BalanceIcon from '@mui/icons-material/Balance';

class Signup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        token: '',
        password: '',
        password2: '',
        username: '',
        company: '',
        is_single_person: false,
        first_name: '',
        last_name: '',
        terms: false,
      },
      html: {
        signup_create: true,
        signup_success: false,
        signup_fail: false,
        errors: false,
      },
      feedback: {
        company: '',
        first_name: '',
        last_name: '',
        username: '',
        password: '',
        password2: '',
      },
      status: {
        company: true,
        first_name: true,
        last_name: true,
        username: true,
        password: true,
        password2: true,
      },
      dialog: {
        open: false
      }
    };

    this.handleLoad = this.handleLoad.bind(this);
    this.handleUsernameValidation = this.handleUsernameValidation.bind(this);
    this.handleCompanyValidation = this.handleCompanyValidation.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handlePasswordValidation = this.handlePasswordValidation.bind(this);
    this.handleSignup = this.handleSignup.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleTerms = this.handleTerms.bind(this);

    this.handleOpenDialog = this.handleOpenDialog.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
  }

  handleLoad(token) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'signup-view',
        pagePath: '/signup',
        pageTitle: 'Inicia Registro',
      },
    });
  }

  componentDidMount() {
    this.handleLoad();
  }

  handleUsernameValidation(event) {
    const {data} = {...this.state};
    const currentState = data;
    const {name, value} = event.target;
    currentState[name] = value;

    this.setState({data: currentState});

    let feedback = '';
    let status = false;

    if (value !== '' && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
      authenticationService.checkExistence({username: value})
          .then((response) => {
            if ('username' in response) {
              feedback = 'Este usuario ya existe. Si es tuyo, trata de recuperar la contraseña.';
              status = false;
            } else {
              feedback = '';
              status = true;
            }
            this.setState((prevState) => ({
              feedback: {
                ...prevState.feedback,
                username: feedback,
              },
            }));
            this.setState((prevState) => ({
              status: {
                ...prevState.status,
                username: status,
              },
            }));
          });
    } else {
      feedback = 'Email con formato incorrecto';
      status = false;
      this.setState((prevState) => ({
        feedback: {
          ...prevState.feedback,
          username: feedback,
        },
      }));
      this.setState((prevState) => ({
        status: {
          ...prevState.status,
          username: status,
        },
      }));
    }
  }
  handleTerms(event) {
    const { checked } = event.target;
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        terms: checked
      }
    }))
  }


  handleInputChange(event) {
    const {data} = {...this.state};
    const currentState = data;
    const {name, value, checked} = event.target;
    if (name==='is_single_person') {
      currentState[name] = checked;
    } else {
      currentState[name] = value;
    }
    this.setState({data: currentState});
  }
  handleCompanyValidation(event) {
    const {data} = {...this.state};
    const currentState = data;
    const {name, value} = event.target;
    currentState[name] = value;
    this.setState({data: currentState});

    if (value !== '') {
      authenticationService.checkExistence({company: value})
          .then((response) => {
            if ('company' in response) {
              this.setState((prevState) => ({
                feedback: {
                  ...prevState.feedback,
                  company: 'Este nombre de empresa ya existe',
                },
              }));
              this.setState((prevState) => ({
                status: {
                  ...prevState.status,
                  company: false,
                },
              }));
            } else {
              this.setState((prevState) => ({
                feedback: {
                  ...prevState.feedback,
                  company: '',
                },
              }));
              this.setState((prevState) => ({
                status: {
                  ...prevState.status,
                  company: true,
                },
              }));
            }
          });
    } else {
      this.setState((prevState) => ({
        feedback: {
          ...prevState.feedback,
          company: 'Debes ingresar un nombre de empresa',
        },
      }));
      this.setState((prevState) => ({
        status: {
          ...prevState.status,
          company: false,
        },
      }));
    }
  }

  handlePassword(value) {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        password: value,
      },
    }),
    this.handlePasswordValidation(null, value),
    );

    if (value === '') {
      this.setState((prevState) => ({
        feedback: {
          ...prevState.feedback,
          password: 'Debes ingresar una nueva contraseña',
        },
      }));
      this.setState((prevState) => ({
        status: {
          ...prevState.status,
          password: false,
        },
      }));
    } else {
      const score = zxcvbn(value).score;

      if (score < 3) {
        this.setState((prevState) => ({
          feedback: {
            ...prevState.feedback,
            password: 'Password muy débil. Trata con más caractéres, números y/o símbolos',
          },
        }));
        this.setState((prevState) => ({
          status: {
            ...prevState.status,
            password: false,
          },
        }));
      } else {
        this.setState((prevState) => ({
          feedback: {
            ...prevState.feedback,
            password: '',
          },
        }));
        this.setState((prevState) => ({
          status: {
            ...prevState.status,
            password: true,
          },
        }));
      }
    }
  }

  handlePasswordValidation(value, p_input = false) {
    if (!p_input) {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          password2: value,
        },
      }));
      p_input = this.state.data.password;
    } else {
      value = this.state.data.password2
    }

    if (value === p_input && value !== '') {
      this.setState((prevState) => ({
        feedback: {
          ...prevState.feedback,
          password2: '',
        },
      }));
      this.setState((prevState) => ({
        status: {
          ...prevState.status,
          password2: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        feedback: {
          ...prevState.feedback,
          password2: 'Las dos contraseñas deben ser iguales',
        },
      }));
      this.setState((prevState) => ({
        status: {
          ...prevState.status,
          password2: false,
        },
      }));
    }
  }

  handleSignup(event) {
    event.preventDefault();

    let ok = true;

    console.log(this.state.status)
    if (!(
      (!this.state.data.is_single_person &&
              this.state.status['company'] &&
              this.state.status['username'] &&
              this.state.status['password'] &&
              this.state.status['password2'] &&
              (this.state.data.terms)
              ) ||
          ( this.state.data.is_single_person &&
              this.state.status['username'] &&
              this.state.status['password'] &&
              this.state.status['password2'] &&
              (this.state.data.terms))
      )
    ) {
      console.log(1)
      ok = false;
      this.setState((prevState) => ({html: {...prevState.html,
        errors: true,
      }}));
      return false;
    }

    if (!this.state.data.is_single_person) {
      if (!this.state.data['company'] || this.state.data['company']==='') {
        ok = false;
        this.setState((prevState) => ({
          feedback: {...prevState.feedback,
            company: 'Debes ingresar un nombre de empresa',
          },
          status: {...prevState.status,
            company: false,
          },
        }));
      } else {
        this.setState((prevState) => ({
          feedback: {...prevState.feedback,
            company: '',
          },
          status: {...prevState.status,
            company: true,
          },
        }));
      }
    } else {
      if (!this.state.data['first_name'] || this.state.data['first_name']==='' ||
          !this.state.data['last_name'] || this.state.data['last_name']===''
      ) {
        ok = false;

        this.setState((prevState) => ({
          feedback: {...prevState.feedback,
            first_name: 'Debes ingresar un nombre y apellido',
            last_name: '',
          },
          status: {...prevState.status,
            first_name: false,
            last_name: false,
          },
        }));
      } else {
        this.setState((prevState) => ({
          feedback: {...prevState.feedback,
            first_name: '',
            last_name: '',
          },
          status: {...prevState.status,
            first_name: true,
            last_name: true,
          },
        }));
      }
    }

    if (!this.state.data['username'] || this.state.data['username']==='') {
      ok = false;
    }
    if (!this.state.data['password'] || this.state.data['password']==='') {
      ok = false;
    }
    if (!this.state.data['password2'] || this.state.data['password2']==='') {
      ok = false;
    }
    if (!ok) {
      this.setState((prevState) => ({html: {...prevState.html,
        errors: true,
      }}));
      return false;
    } else {
      this.setState((prevState) => ({html: {...prevState.html,
        errors: false,
      }}));
    }

    const body = {
      company: this.state.data.company,
      first_name: this.state.data.first_name,
      last_name: this.state.data.last_name,
      is_single_person: this.state.data.is_single_person,
      username: this.state.data.username,
      password: this.state.data.password,
    }

    authenticationService.signup(body)
        .then((response) => {
          if (!response) {
            this.setState((prevState) => ({
              html: {
                ...prevState.html,
                signup_create: false,
                signup_success: false,
                signup_fail: true,
              },
            }));
          } else {
            TagManager.dataLayer({
              dataLayer: {
                event: 'signup-success',
                pagePath: '/signup',
                pageTitle: 'Usuario Creado',
              },
            });

            this.setState((prevState) => ({
              html: {
                ...prevState.html,
                signup_create: false,
                signup_success: true,
                signup_fail: false,
              },
            }));
          }
        });
  }
  handleOpenDialog() {
    this.setState(prevState => ({
      dialog: {
        ...prevState.dialog,
        open: true
      }
    }))
  }

  handleCloseDialog() {
    this.setState(prevState => ({
      dialog: {
        ...prevState.dialog,
        open: false
      }
    }))
  }


  render() {
    return (
      <LoginLayout title='Signup'>
        {this.state.html.signup_create &&
          <Box>
            <Typography variant='h6'>Creación de cuenta</Typography>
            <form onSubmit={this.handleSignup}>
              <FormControlLabel
                control={
                  <Switch checked={this.state.data.is_single_person}
                    onChange={this.handleInputChange}
                    color="primary"
                    name="is_single_person"
                    id="is_single_person"
                    label='¿Eres una persona natural?'
                    inputProps={{'aria-label': 'primary checkbox'}}
                  />
                }
                label="¿Eres una persona natural?"
              />
              {!this.state.data.is_single_person ?
              <Box py={1}>
                <TextField label='Nombre de la empresa'
                  type="text"
                  name="company"
                  error={!this.state.status.company}
                  value={this.state.data.company}
                  helperText={this.state.feedback.company}
                  fullWidth
                  onChange={(event) => this.handleCompanyValidation(event)}
                />
              </Box>:
              <Box>
                <Box py={1}>
                  <TextField label='Nombres'
                    type="text"
                    name="first_name"
                    error={!this.state.status.first_name}
                    value={this.state.data.first_name}
                    helperText={this.state.feedback.first_name}
                    fullWidth
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </Box>

                <Box py={1}>
                  <TextField label='Apellidos'
                    type="text"
                    name="last_name"
                    error={!this.state.status.last_name}
                    value={this.state.data.last_name}
                    helperText={this.state.feedback.last_name}
                    fullWidth
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </Box>
              </Box>
              }
              <Box py={1}>
                <TextField label='E-mail'
                  type="text"
                  name="username"
                  error={!this.state.status.username}
                  value={this.state.data.username}
                  helperText={this.state.feedback.username}
                  fullWidth
                  onChange={(event) => this.handleUsernameValidation(event)}
                />
              </Box>
              <Box py={1}>
                <TextField label="Contraseña"
                  type="password"
                  name="password"
                  error={this.state.status.password === false ? !this.state.status.password : null}
                  value={this.state.data.password}
                  helperText={this.state.feedback.password}
                  fullWidth
                  onChange={(event) => this.handlePassword(event.target.value)}
                />
              </Box>
              <Box py={1}>
                <TextField label="Validar contraseña"
                  type="password"
                  name="password2"
                  value={this.state.data.password2}
                  error={this.state.status.password2 === false ? !this.state.status.password2 : null}
                  helperText={this.state.feedback.password2}
                  fullWidth
                  onChange={(event) => this.handlePasswordValidation(event.target.value)}
                />
              </Box>
              <Box py={1}>
                <FormControlLabel control={
                                    <Checkbox
                                      checked={this.state.data.terms}
                                      onChange={this.handleTerms}
                                      name="terms"
                                      color="primary"
                                    />
                                  }
                                  label="Acepto la Política de uso"
                                  />
                <Box align='right'>
                  <Button onClick={this.handleOpenDialog} color='primary'>Ver política</Button>
                </Box>
              </Box>
              <Box py={1}>
                <Button onClick={this.handleSignup} variant='contained' color='primary' fullWidth>Crear cuenta</Button>
              </Box>
              <Box my={2}>
                <Divider />
              </Box>
              { this.state.html.errors &&
              <Box>
                <Alert severity="error">
                  Debes completar todos los campos correctamente primero
                </Alert>
              </Box>
              }
              <Box py={1}>
                <Button component={Link} to={'/login'} color='secondary' variant="outlined" fullWidth>Volver a la autenticación</Button>
              </Box>
            </form>
          </Box>
        }

        {this.state.html.signup_success &&
          <Box>
            <Alert severity="success">
              <Typography variant='h6'>
                Valide su cuenta ahora en su correo electrónico
              </Typography>
            </Alert>
            <Box p={1}>
              <Typography variant='body2'>
                Dentro de pocos segundos debiera recibir un correo para confirmar la cuenta.
                Luego podrá acceder a nuestro portal con su nuevo usuario y contraseña.
              </Typography>
            </Box>
            <Box p={1} align='right'>
              <Button component={Link} to='/login' color='primary'>Volver a la autenticación</Button>
            </Box>
          </Box>
        }
        {this.state.html.signup_fail &&
          <Box>
            <Alert severity="error">
              Error a crear la cuenta
            </Alert>
            <Typography variant='body2'>
              Ocurrió un error al crear la cuenta. Si vuelves a tener este problema, contáctanos en contacto@axonio.dev.
            </Typography>
            <Box p={1} align='right'>
              <Button component={Link} to='/login' color='primary'>Ingresar a la plataforma</Button>
            </Box>
          </Box>
        }
        <Dialog open={this.state.dialog.open}
          onClose={() => this.handleCloseDialog()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Typography variant='h4' color='primary'>
              <BalanceIcon/> Política de Uso
            </Typography>
            <Box py={1}>
              <Typography color="textSecondary">
              AxonRT SPA es una empresa de telecomunicaciones con casa matriz en Chile. Los términos y condiciones de uso a continuación, 
              en ningún caso excluye otras leyes del territorio nacional que determinen la relación entre un proveedor de telecomunicaciones y un cliente.
              </Typography>
            </Box>
            <Typography variant='overline'>
              Información del cliente
            </Typography>
            <Box py={1}>
              <Typography color="textSecondary">
              AxonRT SPA protegerá los datos personales de sus clientes, garantizando que serán recolectados y almacenados para fines propios del 
              uso de las herramientas, para ofertas de nuevas funcionalidades, nuevos productos y notificaciones de servicio. En cualquier momento 
              el cliente/usuario podrá solicitar la modificación o eliminación de sus datos personales y/o el no envío de notificaciones.
              </Typography>
            </Box>
            <Typography variant='overline'>
              Gestión de tráfico SMS
            </Typography>
            <Box py={1}>
              <Typography color="textSecondary">
              La velocidad de envío de SMS de AxonRT SPA está sujeta a la tasa que dispone cada uno de los operadores de telefonía en Chile, que tiene como 
              tope 10 SMS/Seg por operador. Dicha tasa se distribuye entre el conjunto de clientes de AxonRT SPA, si bien pudiese aumentar previo acuerdo entre 
              el cliente y el proveedor, acuerdo que deberá quedar por escrito. Por otro lado, los operadores de telefonía en Chile no aseguran el servicio
              ni la tasa de envío durante la totalidad del tiempo. De ser el caso, AxonRT SPA no se hace responsable por los problemas que aquello pudiese ocasionar. 
              </Typography>
            </Box>

            <Typography variant='overline'>
              Modificaciones al precio
            </Typography>
            <Box py={1}>
              <Typography color="textSecondary">
              Todos los servicios estarán sujetos a modificaciones de precio. Dichas modificaciones serán anunciadas con al menos 30 días de anticipación, 
              donde el nuevo precio aplicará únicamente para todo incremento de saldo posterior a la fecha de notificación. 
              </Typography>
            </Box>

            <Typography variant='overline'>
              Pagos
            </Typography>
            <Box py={1}>
              <Typography color="textSecondary">
              Todos los servicios contratados en la plataforma funcionan bajo dos modalidades. En primer lugar, mediante carga de créditos, las que 
              pueden ocurrir manualmente o por pagos recurrentes. Los créditos cargados en plataforma, una vez realizado el pago correspondiente, 
              no serán devueltos. En segundo lugar, mediante pagos a fin de mes. Para esta modalidad los clientes deberán haberse solicitado los 
              créditos temporales en forma anticipada, los que una vez acordados, serán activados por AxonRT SPA en la plataforma.
              </Typography>
            </Box>

            <Typography variant='overline'>
              Cancelación del servicio o suspensión de pagos, por parte del cliente
            </Typography>
            <Box py={1}>
              <Typography color="textSecondary">
              El cliente siempre goza de la potestad de interrumpir los servicios que consume, mediante las opciones de bloqueo de credenciales. En el caso 
              de pagos recurrentes, el cliente siempre podrá desinscribir su medio de pago. La cancelación del servicio o suspensión de pagos, no excluye al 
              cliente de las responsabilidades de pago que mantenga pendientes con AxonRT SPA.
              </Typography>
            </Box>

            <Typography variant='overline'>
              Privacidad de las comunicaciones y acciones sobre contenido ilícito o spam
            </Typography>
            <Box py={1}>
              <Typography color="textSecondary">
              La información de los SMS y llamados telefónicos serán confidenciales. Sin embargo, AxonRT SPA se guarda el derecho a revisar el contenido de acuerdo 
              a las condiciones establecidas en el siguiente punto. En caso de determinar que el contenido es de carácter ilícito, AxonRT SPA podrá realizar las 
              denuncias correspondientes además de bloquear completamente el acceso a los servicios ofertados. En caso de determinar que el contenido es de 
              carácter spam, AxonRT SPA se reserva el derecho de analizar reincidencias de reclamos por parte de usuarios, lo que puede derivar en el bloqueo 
              de servicios para un cliente.
              </Typography>
            </Box>

            <Typography variant='overline'>
              Contenido ilícito
            </Typography>
            <Box py={1}>
              <Typography color="textSecondary">
              AxonRT SPA no permite la distribución de ningún contenido a través de nuestros servicios que infrinja la ley, comercialice productos o servicios ilegales, 
              o sea sexualmente explícito. Para ello, AxonRT SPA cuenta con detectores automáticos que alertan del contenido y en determinados casos también bloquean
              a un cliente. Al mismo tiempo, AxonRT SPA puede revisar manualmente dicho contenido, al haberse detectado en cualquier tipo de alerta. 
              La interpretación final sobre el contenido queda en exclusiva potestad de AxonRT SPA, sin embargo siempre el cliente involucrado será notificado.
              </Typography>
            </Box>

            <Typography variant='overline'>
              Contenido SPAM
            </Typography>
            <Box py={1}>
              <Typography color="textSecondary">
              AxonRT SPA no permite la distribución de contenidos masivos que no cuenten con una autorización por parte del usuario receptor de SMS o telefonía. 
              El cliente es responsable del uso de mecanismos de desinscripción de listas que provee la plataforma de AxonRT SPA u otros mecanismos que sean adoptados 
              por el mismo cliente. AxonRT SPA adoptará medidas para detectar reclamos por parte de usuarios receptores de SMS y telefonía.
              </Typography>
            </Box>

            <Typography variant='overline'>
              Responsabilidad sobre el contenido de los mensajes o llamados
            </Typography>
            <Box py={1}>
              <Typography color="textSecondary">
              AxonRT SPA no se hace responsable de ninguna forma por el contenido de los mensajes ni llamados de sus clientes y colaborará con cualquier 
              instancia judicial u organismo de investigación del Estado, si estos lo solicitan.
              </Typography>
            </Box>

            <Typography variant='overline'>
              Calidad del Servicio
            </Typography>
            <Box py={1}>
              <Typography color="textSecondary">
              AxonRT SPA hará en forma permanente todos los esfuerzos posibles por asegurar un servicio de excelencia. Sin embargo, en caso de cualquier
              evento que comprometa los servicios ofertados, ya sea a causa de los canales de comunicación, nuestra infraestructura de servidores o 
              cualquier otro componente de nuestra arquitectura de sistemas, AxonRT SPA no será responsable de los perjuicios que pueda producir en los clientes. 
              </Typography>
            </Box>

            <Typography variant='overline'>
              Resolución de Conflictos
            </Typography>
            <Box py={1}>
              <Typography color="textSecondary">
              Ante cualquier disputa entre el Cliente y AxonRT SPA, que no logre un mutuo acuerdo en una primera instancia respecto a cual sea el motivo, 
              ambos derivarán la responsabilidad del arbitraje en un árbitro que sea acordado entre ambas partes, el cual no podrá tener ninguna relación 
              previa ni interés previo favorable a algunas de las partes. De no haber acuerdo sobre el árbitro, las partes confieren mandato especial e 
              irrevocable a la Cámara de Comercio de Santiago A.G. para que designe un árbitro que resuelva la disputa, de acuerdo al reglamento interno 
              de la misma Cámara de Comercio de Santiago A.G. para este tipo de arbitrajes.
              </Typography>
            </Box>

          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleCloseDialog()} color="secondary">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </LoginLayout>
    );
  }
}

export default withRouter(Signup);
